import { forwardRef } from 'react';

import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
// core components
import makeStyles from '@mui/styles/makeStyles';
import componentsStyle from 'assets/jss/nextjs-material-kit-pro/pages/presentationSections/componentsStyle.js';
import Slide from '@mui/material/Slide';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from 'components/MaterialDashboard/CustomButtons/Button.js';
// @material-ui/icons
import Close from '@mui/icons-material/Close';
// feathers backend-related
import { useNewsfeedSystemStateSingle } from 'components/Custom/FeathersLoadingHooks';
// custom styling
import newsItemClasses from 'components/NewsfeedManagement/NewsItem.module.css';

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

const useStyles = makeStyles(componentsStyle);

export default function NewsItemModal({ showModal, setShowModal, id }) {
    const newsfeedSystemStateSingle = useNewsfeedSystemStateSingle(id);
    const classes = useStyles();

    return (
        <div>
            <Dialog
                PaperProps={{
                    sx: {
                        maxWidth: '100vh',
                    }
                }}
                open={showModal}
                TransitionComponent={Transition}
                keepMounted
                onClose={() => setShowModal(false)}
                aria-labelledby="classic-modal-slide-title"
                aria-describedby="classic-modal-slide-description"
            >

                <DialogTitle
                    id="classic-modal-slide-title"
                    disableTypography
                >
                    <Button
                        simple
                        key="close"
                        aria-label="Close"
                        onClick={() => setShowModal(false)}
                    >
                        {' '}
                        <Close className={classes.modalClose}
                            sx={{
                                color: '#000'
                            }}
                        />
                    </Button>
                </DialogTitle>
                <DialogContent
                    id="classic-modal-slide-description"
                    className={classes.modalBody}
                >
                    <img alt={newsfeedSystemStateSingle.data?.name} src={newsfeedSystemStateSingle.data?.imageLink} style={{
                        height: '550px',
                        width: '100%',
                        objectFit: 'cover',
                    }} />
                    <h1 className={classes.title}>
                        {newsfeedSystemStateSingle.data?.name}
                    </h1>
                    <h6 className={classes.description}>
                        {(new Date(newsfeedSystemStateSingle.data?.createdAt)).toDateString() || 'Unknown'}
                    </h6>
                    <ReactMarkdown className={newsItemClasses.body} rehypePlugins={[rehypeRaw]}>
                        {newsfeedSystemStateSingle.data?.description}
                    </ReactMarkdown>
                </DialogContent>
                <DialogActions className={classes.modalFooter}>
                    <Button onClick={() => setShowModal(false)} color="secondary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}