import classNames from 'classnames';

import makeStyles from '@mui/styles/makeStyles';

// @mui/icons-material

import GridContainer from 'components/MaterialKit/Grid/GridContainer.js';
import GridItem from 'components/MaterialKit/Grid/GridItem.js';
import Button from 'components/MaterialKit/CustomButtons/Button.js';
import SubjectIcon from '@mui/icons-material/Subject';

import cardsStyle from 'assets/jss/nextjs-material-kit-pro/pages/presentationSections/cardsStyle.js';

const useStyles = makeStyles(cardsStyle);

export default function SectionProduct() {
    const classes = useStyles();
    return (
        <div className={classNames(classes.section, classes.sectionPrimary)}>
            <div className={classes.container}>
                <GridContainer justify="center">
                    <GridItem md={7} sm={7}>
                        <div className={classes.imageContainer}>
                            <img src={require('assets/img/monitoring2.jpg')} alt="views" />
                        </div>
                    </GridItem>
                    <GridItem md={4} sm={5} className={classes.mlAuto}>
                        <div className={classes.sectionDescription}>
                            <a className={classes.anchor} id='the-project'  />
                            <h2 className={classes.title}>The Project</h2>
                            <h6 className={classes.description}>
                Innovation in the Mining Industry
                            </h6>
                            <h5 className={classes.description}>
                We are evaluating a range of monitoring technologies and their
                suitability for application to providing advance warning of
                potential TSFs instabilities. The outcomes of this project
                provide meaningful insight into appropriately selected
                instrumentation that measure meaningful parameters. These
                measurements will act as trigger warnings for detecting the
                potential onset of failures that may be prevented.
                            </h5>
                        </div>
                        <Button color="rose" href="/about-us#the-project"><SubjectIcon />Read more</Button>
                    </GridItem>
                </GridContainer>
            </div>
        </div>
    );
}
