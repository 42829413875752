import { useState } from 'react';

import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
// core components
import GridContainer from 'components/MaterialKit/Grid/GridContainer.js';
import GridItem from 'components/MaterialKit/Grid/GridItem.js';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import Button from 'components/MaterialKit/CustomButtons/Button.js';
import SubjectIcon from '@mui/icons-material/Subject';
import makeStyles from '@mui/styles/makeStyles';
import componentsStyle from 'assets/jss/nextjs-material-kit-pro/pages/presentationSections/componentsStyle.js';
import Skeleton from '@mui/material/Skeleton';
import NewsItemModal from 'components/NewsfeedManagement/NewsItemModal';
// feathers backend-related
import { useNewsfeedSystemState } from 'components/Custom/FeathersLoadingHooks';
// custom styling
import newsItemClasses from 'components/NewsfeedManagement/NewsItem.module.css';

const useStyles = makeStyles(componentsStyle);

export default function SectionNews() {

    const classes = useStyles();
    const newsfeedSystemState = useNewsfeedSystemState();

    const responsive = {
        anyDevice: {
            breakpoint: { max: 4000, min: 0 },
            items: 1
        }
    };

    const displayData = newsfeedSystemState.isLoading ?
        [...Array(9)].map((element) => {
            return {
                image: <Skeleton variant="rectangular" width={210} height={118} />,
                name: <Skeleton width="10em" />,
                description: <Skeleton width="10em" />,
                createdAt: <Skeleton width="10em" />
            };
        })
        :
        newsfeedSystemState.queryResult?.data.map((tech) => {
            return {
                image: (<img alt={tech.name} src={tech.imageLink || placeholderImage} style={{
                    height: '550px',
                    width: '100%',
                    objectFit: 'cover',
                }} />),
                name: tech.name,
                description:
                    (<ReactMarkdown className={newsItemClasses.body + ' ' + newsItemClasses.preview} rehypePlugins={[rehypeRaw]}>
                        {tech.description}
                    </ReactMarkdown>),
                createdAt: (new Date(tech.createdAt)).toDateString() || 'Unknown',
                id: tech._id
                // 2022-07-25T10:08:56.770Z.split('T')[0]
            };
        }) || [];

    const [showModal, setShowModal] = useState(false);
    const [modalID, setModalID] = useState(null);
    return (
        <>
            {displayData.length ? (
                <div style={{
                    margin: '50px'
                }}>
                    <h1 className={classes.title} style={{
                        paddingTop: '10px',
                        textAlign: 'center'
                    }}>
                        <NewspaperIcon sx={{
                            marginRight: '5px',
                            fontSize: '26pt'
                        }} /> Newsfeed
                    </h1>
                    <Carousel responsive={responsive}
                        ssr={true} // means to render carousel on server-side.
                        transitionDuration={500}
                        containerClass="carousel-container"
                        dotListClass="custom-dot-list-style"
                        itemClass="carousel-item-padding-40-px"
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            flexWrap: 'wrap'
                        }}>
                        {displayData.map(item => (

                            <GridContainer key={item.id} sx={{
                                display: 'flex',
                                justifyContent: 'center'
                            }}>
                                <GridItem lg={4} md={6} sm={12}>
                                    <div style={{
                                        height: '100%'
                                    }}>
                                        {item.image}
                                    </div>
                                </GridItem>
                                <GridItem lg={4} md={5} sm={12} >
                                    <h2 className={classes.title}>{item.name}</h2>
                                    <h6 className={classes.description}>
                                        {item.createdAt}
                                    </h6>
                                    <div className={classes.description} style={{
                                        overflowY: 'hidden',
                                        textOverflow: 'ellipsis',
                                        maxHeight: '260px',
                                    }}>
                                        {item.description}
                                    </div>
                                    <Button color="primary" onClick={() => {
                                        setShowModal(true);
                                        setModalID(item.id);
                                    }}><SubjectIcon />Read more</Button>


                                </GridItem>
                            </GridContainer>
                        ))
                        }
                    </Carousel>
                    <NewsItemModal showModal={showModal} setShowModal={setShowModal} id={modalID} />
                </div>
            ) : <></>}
        </>
    );
}
