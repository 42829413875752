import {useEffect} from 'react';
import { services } from 'store/feathersClient';
import { useSelector } from 'react-redux';


export const useUserState = () =>{
    useEffect(() =>{
        services.users.find({
            query: { $populate: 'organisation' }
        }).catch(error=> {
            //Handled by Redux Saga
        });
    }, []);
    
    const userState = useSelector(state => state.users);
    return userState;
};

export const useMonitoringSystemState = (additionalParams) =>{
    useEffect(() =>{
        services['monitoring-system'].find(additionalParams).catch(error=> {
            //Handled by Redux Saga
        });
    }, []);
    
    const monitoringSystemState = useSelector(state => state['monitoring-system']);
    return monitoringSystemState;
};

export const useMonitoringSystemStateSingle = (_id,additionalParams) =>{
    useEffect(() =>{
        services['monitoring-system'].get(_id,additionalParams).catch(error=> {
            //Handled by Redux Saga
        });
    }, [_id]);
    
    const monitoringSystemState = useSelector(state => state['monitoring-system']);
    return monitoringSystemState;
};

export const useNewsfeedSystemState = (additionalParams) =>{
    useEffect(() =>{
        services['newsfeed-system'].find(additionalParams).catch(error=> {
            //Handled by Redux Saga
        });
    }, []);
    
    const newsfeedSystemState = useSelector(state => state['newsfeed-system']);
    return newsfeedSystemState;
};

export const useNewsfeedSystemStateSingle = (_id,additionalParams) =>{
    useEffect(() =>{
        services['newsfeed-system'].get(_id,additionalParams).catch(error=> {
            //Handled by Redux Saga
        });
    }, [_id]);
    
    const newsfeedSystemState = useSelector(state => state['newsfeed-system']);
    return newsfeedSystemState;
};

export const useRecords = (additionalParams) =>{
    useEffect(() =>{
        services.records.find(additionalParams).catch(error=> {
            //Handled by Redux Saga
        });
    }, []);
    
    const recordsState = useSelector(state => state.records);
    return recordsState;
};

export const useOrganisations = (additionalParams) =>{
    useEffect(() =>{
        services.organisations.find(additionalParams).catch(error=> {
            //Handled by Redux Saga
        });
    }, []);
    
    const organisationsState = useSelector(state => state.organisations);
    return organisationsState;
};