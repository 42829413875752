import {
    blackColor,
    primaryColor,
    roseColor,
    infoColor,
    successColor,
    warningColor,
    dangerColor,
    hexToRgb,
} from 'assets/jss/nextjs-material-kit-pro.js';

const parallaxStyle = {
    parallax: {
        height: '100vh',
        maxHeight: '1600px',
        overflow: 'hidden',
        position: 'relative',
        backgroundPosition: '50%',
        backgroundSize: 'cover',
        margin: '0',
        padding: '0',
        border: '0',
        display: 'flex',
        alignItems: 'center',
    },
    filter: {},
    primaryColor: {
        '&:before': {
            background: 'rgba(' + hexToRgb(blackColor) + ', 0.5)',
        },
        '&:after': {
            background:
        'linear-gradient(60deg,rgba(' +
        hexToRgb(primaryColor[0]) +
        ',.40),rgba(' +
        hexToRgb(primaryColor[3]) +
        ',.50))',
        },
        '&:after,&:before': {
            position: 'absolute',
            zIndex: '1',
            width: '100%',
            height: '100%',
            display: 'block',
            left: '0',
            top: '0',
            content: '\'\'',
        },
    },
    roseColor: {
        '&:before': {
            background: 'rgba(' + hexToRgb(blackColor) + ', 0.5)',
        },
        '&:after': {
            background:
        'linear-gradient(60deg,rgba(' +
        hexToRgb(roseColor[3]) +
        ',.56),rgba(' +
        hexToRgb(roseColor[4]) +
        ',.95))',
        },
        '&:after,&:before': {
            position: 'absolute',
            zIndex: '1',
            width: '100%',
            height: '100%',
            display: 'block',
            left: '0',
            top: '0',
            content: '\'\'',
        },
    },
    darkColor: {
        '&:before': {
            background: 'rgba(' + hexToRgb(blackColor) + ', 0.5)',
        },
        '&:after,&:before': {
            position: 'absolute',
            zIndex: '1',
            width: '100%',
            height: '100%',
            display: 'block',
            left: '0',
            top: '0',
            content: '\'\'',
        },
    },
    infoColor: {
        '&:before': {
            background: 'rgba(' + hexToRgb(blackColor) + ', 0.5)',
        },
        '&:after': {
            background:
        'linear-gradient(60deg,rgba(' +
        hexToRgb(infoColor[5]) +
        ',.56),rgba(' +
        hexToRgb(infoColor[6]) +
        ',.95))',
        },
        '&:after,&:before': {
            position: 'absolute',
            zIndex: '1',
            width: '100%',
            height: '100%',
            display: 'block',
            left: '0',
            top: '0',
            content: '\'\'',
        },
    },
    successColor: {
        '&:before': {
            background: 'rgba(' + hexToRgb(blackColor) + ', 0.5)',
        },
        '&:after': {
            background:
        'linear-gradient(60deg,rgba(' +
        hexToRgb(successColor[5]) +
        ',.56),rgba(' +
        hexToRgb(successColor[6]) +
        ',.95))',
        },
        '&:after,&:before': {
            position: 'absolute',
            zIndex: '1',
            width: '100%',
            height: '100%',
            display: 'block',
            left: '0',
            top: '0',
            content: '\'\'',
        },
    },
    warningColor: {
        '&:before': {
            background: 'rgba(' + hexToRgb(blackColor) + ', 0.5)',
        },
        '&:after': {
            background:
        'linear-gradient(60deg,rgba(' +
        hexToRgb(warningColor[5]) +
        ',.56),rgba(' +
        hexToRgb(warningColor[6]) +
        ',.95))',
        },
        '&:after,&:before': {
            position: 'absolute',
            zIndex: '1',
            width: '100%',
            height: '100%',
            display: 'block',
            left: '0',
            top: '0',
            content: '\'\'',
        },
    },
    dangerColor: {
        '&:before': {
            background: 'rgba(' + hexToRgb(blackColor) + ', 0.5)',
        },
        '&:after': {
            background:
        'linear-gradient(60deg,rgba(' +
        hexToRgb(dangerColor[5]) +
        ',.56),rgba(' +
        hexToRgb(dangerColor[6]) +
        ',.95))',
        },
        '&:after,&:before': {
            position: 'absolute',
            zIndex: '1',
            width: '100%',
            height: '100%',
            display: 'block',
            left: '0',
            top: '0',
            content: '\'\'',
        },
    },
    small: {
        height: '40vh',
        minHeight: '40vh',
        maxHeight: '650px',
    },
};

export default parallaxStyle;
