
const SideDots = ({elements}) => {
    // Expects an array of objects
    // 2 fields: link and name
    return (
        <>
            <nav id="cd-vertical-nav">
                <ul>
                    {elements.map(({link,name},index)=>(
                        <li key={name}>
                            <a
                                href={link}
                                data-number={index}
                                className=""
                            >
                                <span className="cd-dot" />
                                <span className="cd-label">{name}</span>
                            </a>
                        </li>
                    ))}
                </ul>
            </nav>
            
        </>
    );
};

export default SideDots;
