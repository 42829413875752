import React from 'react';
// nodejs library to set properties for components

import classNames from 'classnames';
import makeStyles from '@mui/styles/makeStyles';


import GridContainer from 'components/MaterialKit/Grid/GridContainer.js';
import GridItem from 'components/MaterialKit/Grid/GridItem.js';
import Parallax from 'components/MaterialKit/Parallax/Parallax.js';

import landingPageStyle from 'assets/jss/nextjs-material-kit-pro/pages/landingPageStyle.js';

import FrontSubsection from 'layouts/FrontSubsection';
import SideDots from 'components/Layout/SideDots';
// Sections for this page
import SectionNews from '../pages-sections/landing-page/SectionNews';
import SectionProduct from 'pages-sections/landing-page/SectionProduct.js';
import SectionTeam from 'pages-sections/landing-page/SectionTeam.js';

const useStyles = makeStyles(landingPageStyle);

const LandingPage = ({ ...rest }) => {
    const classes = useStyles();
    return (
        <>
            <SideDots elements={[
                {
                    link:'#',
                    name:'Overview'
                },
                {
                    link:'#about-us',
                    name:'About Us'
                },
                {
                    link:'#the-project',
                    name:'The Project'
                },
                // {
                //     link:'#news',
                //     name:'News'
                // },
            ]} />
            <Parallax image={require('assets/img/tails3.png')} filter="primary">
                <div className={classes.container}>
                    <GridContainer direction="row" justifyContent="center" alignItems="center">
                        <GridItem xs={12} style={{textAlign:'center'}}>
                            <h1 className={classes.title}>Meaningful monitoring of Tailings Storage Facilities</h1>
                        </GridItem>
                    </GridContainer>
                </div>
            </Parallax>
            <div className={classNames(classes.main, classes.mainRaised)}>
                <SectionNews />
                <SectionTeam />
                <SectionProduct />
            </div>
        </>
    );
};

LandingPage.layout = FrontSubsection;

export default LandingPage;