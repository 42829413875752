// core components
import GridContainer from 'components/MaterialKit/Grid/GridContainer.js';
import GridItem from 'components/MaterialKit/Grid/GridItem.js';
import makeStyles from '@mui/styles/makeStyles';
import Button from 'components/MaterialKit/CustomButtons/Button.js';
import SubjectIcon from '@mui/icons-material/Subject';
import componentsStyle from 'assets/jss/nextjs-material-kit-pro/pages/presentationSections/componentsStyle.js';

const useStyles = makeStyles(componentsStyle);

export default function SectionComponents() {
    const classes = useStyles();
    return (
        <div className={classes.section} style={{marginBottom:'5vh'}}>
            <div className={classes.container}>
                <GridContainer justify="center">
                    <GridItem md={5} lg={5} sm={12} xs={12}>
                        <a className={classes.anchor} id='about-us'  />
                        <h2 className={classes.title}>About Us</h2>
                        <h6 className={classes.description}>
                            The mission to make meaning in mining data
                        </h6>
                        <h5 className={classes.description}>
                        We are a team of geotechnical engineers integrating soils laboratory element testing, numerical modelling, and centrifuge testing with real-life monitoring data to provide meaningful monitoring solutions to the mining industry. We are based at the University of Western Australia in the School of Engineering. With the financial support and technological help from a range of monitoring technologies specialists, we bridge the knowledge gap between monitoring measurements and the meaningful interpretation of the data.
                        </h5>
                        <Button color="primary" href="/about-us"><SubjectIcon />Read more</Button>

                    </GridItem>
                    <GridItem xs={12} sm={12} md={6} lg={6} className={classes.mlAuto}>
                        <div className={classes.imageContainer}>
                            <img
                                src={require('assets/img/tailingsdam-2.png')}
                                alt="macbook"
                                className={classes.componentsMacbook}
                            />
                            {/* <img
                                src={require('assets/img/undraw6.svg')}
                                alt="macbook"
                                className={classes.shoppingCart}
                            />
                            <img
                                src={require('assets/img/undraw3.svg')}
                                alt="macbook"
                                className={classes.cardImage}
                            />
                            <img
                                src={require('assets/img/undraw4.svg')}
                                alt="macbook"
                                className={classes.twitterImage}
                            /> */}
                        </div>
                    </GridItem>                       
                </GridContainer>
            </div>
        </div>
    );
}
